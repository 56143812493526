import { logger } from '@exchange/libs/utils/simple-logger/src';

import { ChannelTemplate, type ChannelListenerTemplate, type SubscribeCallbacks } from './Channel.template';
import { wssLogPrefix } from '../decorators';
import { WSIncomingEventTypes, WSOutgoingEventTypes, WSOutgoingEventTypesSpecial } from '../websocket-spot-model';
import type { WSIncomingBasicMessagePayload, WSIncomingUnsubscribeMessagePayload, WSOutgoingSubscribeMessagePayload } from '../websocket-spot-model';

/* eslint-disable camelcase */
export type WSMarketMessagePayload =
  | WSIncomingUnsubscribeMessagePayload
  | (WSIncomingBasicMessagePayload & {
      instrument_code: string;
    });
/* eslint-enable camelcase */

export type MarketChannelListenerTemplate<T> = ChannelListenerTemplate<T> & {
  market: string;
};

export abstract class MarketsChannelTemplate<MessageType extends WSMarketMessagePayload> extends ChannelTemplate<
  MessageType,
  MarketChannelListenerTemplate<MessageType> & { market: string }
> {
  private depth = 0;

  protected getSubscribeMessage() {
    const markets = this.getUniqueMarkets();

    const subscribeMessage: WSOutgoingSubscribeMessagePayload = {
      type: WSOutgoingEventTypesSpecial.SUBSCRIBE,
      channels: [
        {
          name: this.channelName,
          instrument_codes: markets,
          depth: this.depth,
        },
      ],
    };

    return subscribeMessage;
  }

  override subscribe(listener: MarketChannelListenerTemplate<MessageType>, callbacks?: SubscribeCallbacks) {
    logger.info('Market Channel Subscribe:', {
      channelName: this.channelName,
      market: listener.market,
      existingMarkets: this.getUniqueMarkets(),
      stack: new Error().stack, // This will show us the call stack
    });

    const listenerCountDiff = this.trackListenerCount(() => this.listeners.push(listener));
    const newMarketCount = this.getUniqueMarkets().length;

    logger.info('Market Channel Subscribe Details:', {
      listenerCountDiff,
      newMarketCount,
      allListeners: this.listeners.map((l) => ({ market: l.market, channelName: this.channelName })),
    });

    (async () => {
      if (listenerCountDiff !== 0) {
        if (newMarketCount === 1) {
          await this.openChannel(listener);
        } else {
          await this.updateSubscription();
        }
      }
    })()
      .then(() => {
        callbacks?.success();
      })
      .catch((error) => callbacks?.fail(error));

    return async () => {
      const listenerCountDiffUnsub = this.trackListenerCount(() => {
        this.listeners = this.listeners.filter((l) => l !== listener);
      });

      if (listenerCountDiffUnsub !== 0) {
        if (this.listeners.length === 0) {
          await this.closeChannel();
        } else {
          await this.updateSubscription();
        }
      }
    };
  }

  async restart() {
    logger.log('Restarting channel', this.channelName, this.getUniqueMarkets());

    if (this.unsubscribeFromWebsocket) {
      logger.log('closing channel', this.channelName);
      await this.closeChannel();

      if (this.listeners.length > 0) {
        const fl = this.listeners[0];

        // Todo maybe remove listeners from openChannel?
        logger.log('reopening channel!', this.channelName);

        if (fl) {
          await this.openChannel(fl);
        }
      }
    }
  }

  private trackListenerCount(modifyFn: () => void) {
    const oldMarketCount = this.getUniqueMarkets().length;

    modifyFn();
    const newMarketCount = this.getUniqueMarkets().length;

    return newMarketCount - oldMarketCount;
  }

  private getUniqueMarkets() {
    return [...new Set(this.listeners.map((listener) => listener.market))];
  }

  private async updateSubscription() {
    const markets = this.getUniqueMarkets();
    const message = {
      type: WSOutgoingEventTypes.UPDATE_SUBSCRIPTION,
      channels: [
        {
          name: this.channelName,
          instrument_codes: markets,
          depth: this.depth,
        },
      ],
    };

    try {
      await this.webSocketManager.request({
        message,
        successMatcher: (m) => m.type === WSIncomingEventTypes.SUBSCRIPTION_UPDATED && m.channel_name === this.channelName,
      });
    } catch (e) {
      logger.warn(`${wssLogPrefix}Failed to update subscription for ${this.channelName}:`, e);

      this.handleRequestFailure(e, () => this.updateSubscription());
    }
  }

  protected override messageFilter({ event, listener }: { event: MessageType; listener: MarketChannelListenerTemplate<MessageType> }) {
    if ('instrument_code' in event) {
      return super.messageFilter({ event, listener }) && event.instrument_code === listener.market;
    }

    return false;
  }
}
